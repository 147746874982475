import React from "react";
import { Link } from "gatsby";
import { BlockContent } from "../../common/BlockContent";

import * as styles from "./LinksPage.module.scss";

const LinksPageLink = ({ link }) => {
  let artworkReference = link.artworkReference?.artwork;
  let biographyReference = link.biographyReference?.biography;

  const linkTitle =
    link.title || biographyReference?.title || artworkReference?.title;

  return (
    <article className={styles.link}>
      <LinkTitle link={link} className={styles.linkTitle} />
      <div className="styles.description">
        <BlockContent blocks={link._rawDescription || []} />
      </div>
      <div className="styles.reference">
        {link.biographyReference && (
          <Reference
            reference={link.biographyReference.biography}
            type="biography"
          />
        )}
        {link.artworkReference && (
          <Reference reference={link.artworkReference.artwork} type="artwork" />
        )}
      </div>
    </article>
  );
};

export { LinksPageLink };

function Reference({ reference, type }) {
  let introText = "View in";
  switch (type) {
    case "biography":
      introText = "→ View biography page ";
      break;
    case "artwork":
      introText = "→ View artwork ";
      break;
    default:
      introText = "";
  }
  return (
    <>
      {reference && (
        <>
          {introText}
          <Link to={`/${type}/${reference.slug.current}`}>
            {reference.title}
          </Link>
        </>
      )}
    </>
  );
}

function LinkTitle({ link, className }) {
  if (link.title && link.url) {
    return (
      <div className={className}>
        {link.url && (
          <a
            href={link.url}
            target={link.newTab ? "_blank" : "_self"}
            rel="noreferrer"
          >
            {link.title}
          </a>
        )}
      </div>
    );
  }
  if (!link.url) {
    if (link.biographyReference) {
      return (
        <div className={className}>
          {link.biographyReference && (
            <Link
              to={`/biography/${link.biographyReference.biography.slug.current}`}
            >
              {link.title || link.biographyReference.biography.title}
            </Link>
          )}
        </div>
      );
    }
    if (link.artworkReference) {
      return (
        <div className={className}>
          {link.artworkReference && (
            <Link to={`/artwork/${link.artworkReference.artwork.slug.current}`}>
              {link.title || link.artworkReference.artwork.title}
            </Link>
          )}
        </div>
      );
    }
    return null;
  }
}
