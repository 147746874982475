import React from "react";
import { Link } from "gatsby";
import { BlockContent } from "../../common/BlockContent";
import { LinksPageLink } from "./LinksPageLink";

import * as styles from "./LinksPage.module.scss";

const LinksPage = ({ linksPage }) => {
  return (
    <article className={styles.linksPage}>
      <h1>{linksPage.title}</h1>
      <div className={styles.info}>
        <section className={styles.description}>
          See recent links below and{" "}
          <Link to="/">current and upcoming shows on the home page</Link>.
        </section>
        {linksPage._rawDescription && (
          <section className={styles.description}>
            <BlockContent blocks={linksPage._rawDescription || []} />
          </section>
        )}
        <section className={styles.links}>
          {linksPage.links.length > 0 && (
            <>
              {linksPage.links.map((link, index) => (
                <LinksPageLink link={link} key={index} />
              ))}
            </>
          )}
        </section>
      </div>
    </article>
  );
};

export { LinksPage };
