import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/common/seo";
import { LinksPage } from "../components/pageLayout/LinksPage";

export const links = graphql`
  query LinksPageQuery {
    links: allSanityLinkPageSingleton {
      edges {
        node {
          id
          title
          _rawDescription
          description {
            _type
            _key
            children {
              _key
              _type
              marks
              text
            }
          }
          links {
            title
            url
            newTab
            _rawDescription
            description {
              _type
              _key
              children {
                _key
                _type
                marks
                text
              }
            }
            artworkReference {
              artwork {
                title
                slug {
                  current
                }
              }
            }
            biographyReference {
              biography {
                slug {
                  current
                }
                title
              }
            }
          }
        }
      }
    }
  }
`;

const Links = ({ data }) => {
  const { links } = data;
  const linksPage = links.edges[0].node;

  return (
    <>
      <Seo title="Links" />
      <LinksPage linksPage={linksPage} />
    </>
  );
};

export default Links;
